<template>
    <div>
        <header v-if="!isPreLoad" class="p-3">
            <div class="flex-titular">
                <div>
                    <h1 v-cloak>{{ translator.manteniment }}</h1>
                </div>
                <div></div>
            </div>
        </header>

        <div class="pc service">

            <!-- Spiner -->
            <div v-if="isPreLoad" style="height: 90vh; display:flex; justify-content:center; align-items:center;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->

            <div v-if="!isPreLoad" class="container p-0">
                <table class="table table-striped tabla-incidencias" style="font-size: 14px;">
                    <thead>
                        <tr>
                            <th scope="col">{{ translator.data }}/{{ translator.propietat | toLower }}</th>
                            <th scope="col">{{ translator.incidencia }}</th>
                        </tr>
                    </thead>
                    <tbody v-cloak>
                        <router-link :to="{ name: 'ServiceDetail', params: { service: service.service_id }, query: { id: $route.query.id }}"
                                     v-for="(service, index) in services" 
                                     :key="index" 
                                     custom v-slot="{ navigate }"
                                     class="ServiceDetail" >
                            <tr @click="navigate" role="link">
                                <td>
                                    {{ service.service_date | transformDate }}
                                    <br>
                                    {{ service.lodging_name }}
                                </td>
                                <td style="vertical-align:inherit;">
                                    <div style="display:flex; justify-content:space-between; align-items:center;">
                                        <span>{{ service.service_headline }}</span>
                                        <i class="fal fa-chevron-right" style="line-height: inherit;"></i>
                                    </div>
                                </td>
                            </tr>
                        </router-link>
                    </tbody>
                </table>
            </div>

            <NavbarService :translator="translator" :user="user" />
        </div>
    </div>
</template>

<script>
    import NavbarService from '@/components/NavbarService.vue';
    import { mapState } from "vuex";

    export default {
        name: 'Service',
        components: {
            NavbarService
        },
        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("service", ["services"]),
        },
        watch: {
            services() {
                this.isPreLoad = false;
            }
        },
        async created() {
            try {
                this.isPreLoad = true
                await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
                await this.$store.dispatch("service/getService", { usr: this.$route.query.id });

            } catch (error) {
                console.error(error);
            }
        },
        data() {
            return {
                isPreLoad: false,
            };
        },
        methods: {},
        filters: {
            transformDate(value) {
                if (!value) return '';
                const backendDate = value.split('-');
                return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`;
            },
            toLower(value) {
                if (!value) return "";
                value = value.toString();
                return value.toLowerCase();
            },
        }
    }
</script>

<style scoped> 
    header .flex-titular {
        display: flex;
        justify-content: space-between;
        max-width: 800px;
        margin: auto;
    }

    th {
        cursor: pointer;
    }

    .ServiceDetail {
        cursor: pointer;
    }
    .ServiceDetail:hover {
        box-shadow: 0 .125rem .45rem rgba(0,0,0,.2)
    }
</style>