<template>
  <div>
    <nav id="navbar" runat="server" class="fixed-bottom shadow nav-fill border-top">
      <ul class="nav">
        <!-- Inicio para service -->
        <router-link
          :to="{ name: 'Service', query: { id: $route.query.id }}" 
          class="nav-item py-2">
          <i class="fal fa-list"></i>
          <div class="text-opcio">{{ translator.inici }}</div>
        </router-link>


        <!-- Calendar -->
        <router-link :to="{ name: 'ServiceCalendar', query: { id: $route.query.id }}" class="nav-item py-2">
          <i class="fal fa-calendar-alt"></i>
          <div class="text-opcio">{{ translator.calendari }}</div>
        </router-link>

      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavbarService',
  props: ['user', 'translator'],
}
</script>

<style scoped lang="scss">
#navbar {
  a {
    color: var(--colores-gris-medio);

    &.router-link-exact-active {
      color: var(--azul-oscuro);
    }
  }
}
</style>