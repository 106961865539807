<template>
    <div>
        <header v-if="!isPreLoad" class="p-3">
            <div class="flex-titular">
                <div>
                    <h1 v-cloak>{{ translator.incidencia }}</h1>
                </div>

                <div class="text-right">
                    <router-link class="link-atras" :to="{ name: 'Service', query: { id: $route.query.id }}">
                        <i class="fal fa-undo-alt"></i>
                    </router-link>
                </div>
            </div>
        </header>

        <div class="pc service">

            <!-- Spiner -->
            <div v-if="isPreLoad" style="height: 90vh; display:flex; justify-content:center; align-items:center;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->
            <!-- Titular y información incidencia -->
            <div v-if="serviceDetail.lodging_name && !isPreLoad" class="container incidencia p-4">
                <div class="row incidencia-titulo mb-4">
                    <div class="col">
                        <h5>{{ serviceDetail.service_headline }}</h5>
                    </div>
                </div>

                <div v-if="!validService" class="alert alert-warning" role="alert">
                    *Debe subir una imagen o añadir una descripción*
                </div>

                <div class="row incidencia-info py-2">
                    <div class="col-3 incidencia-titular pt-1">
                        {{ translator.estat }}
                    </div>
                    <div class="col pt-1">
                        {{ serviceDetail.status }}
                    </div>
                    <div class="col-5">
                        <button @click.prevent.stop="endService" class="btn btn-primary btn-sm btn-cerrar-incidencia">{{ translator.tancarincidencia }}</button>
                    </div>
                </div>
                <div class="row incidencia-info py-2">
                    <div class="col-3 incidencia-titular">
                        {{ translator.data }}
                    </div>
                    <div class="col">
                        {{ serviceDetail.service_date | transformDate }}
                    </div>
                </div>
                <div class="row incidencia-info py-2">
                    <div class="col-3 incidencia-titular">
                        {{ translator.propietat }}
                    </div>
                    <div class="col">
                        <a :href="`https://www.google.com/maps/search/?api=1&query=${serviceDetail.lodging_latitude},${serviceDetail.lodging_longitude}`" target="_blank">
                            <i class="fal fa-map-marker-alt"></i>
                            {{ serviceDetail.lodging_name }}
                        </a>
                    </div>
                </div>
                <div class="row incidencia-info py-2">
                    <div class="col-3 incidencia-titular">
                        {{ translator.motiuincidencia }}
                    </div>
                    <div class="col">
                        {{ serviceDetail.service_reason }}
                    </div>
                </div>
                <div class="row incidencia-info py-2 incidencia-border-bottom">
                    <div class="col-3 incidencia-titular">
                        {{ translator.observacions }}
                    </div>
                    <div class="col">
                        {{ serviceDetail.service_text }}
                    </div>
                </div>

                <!-- Fotos del antes -->
                <h6 class="mt-4 mb-3">
                    {{ translator.fotos }}:
                </h6>
                <div class="row mb-3" v-if="serviceDetail.service_image_1 != '' || serviceDetail.service_image_2 != '' || serviceDetail.service_image_3 != ''">
                    <div class="col-4">
                        <img :src="serviceDetail.service_image_1" class="img-fluid" />
                    </div>
                    <div class="col-4">
                        <img :src="serviceDetail.service_image_2" class="img-fluid" />
                    </div>
                    <div class="col-4">
                        <img :src="serviceDetail.service_image_3" class="img-fluid" />
                    </div>
                </div>

                <!-- Subida y muestra de fotos del después -->
                <div class="row mb-2">
                    <div class="col-4">
                        <img v-if="serviceDetail.service_image_4 != ''" :src="serviceDetail.service_image_4" class="img-fluid">
                    </div>
                    <div class="col-4">
                        <img v-if="serviceDetail.service_image_5 != ''" :src="serviceDetail.service_image_5" class="img-fluid">
                    </div>
                    <div class="col-4">
                        <img v-if="serviceDetail.service_image_6 != ''" :src="serviceDetail.service_image_6" class="img-fluid">
                    </div>
                </div>

                <!-- Boton guardar fotos -->
                <div class="row">
                    <image-uploader v-if="numPhoto > 0"
                                    id="image4"
                                    :debug="1"
                                    :maxWidth="1024"
                                    :quality="0.9"
                                    :autoRotate="false"
                                    class="col-12"
                                    :className="['fileinput']"
                                    outputFormat="verbose"
                                    :preview="false"
                                    :capture="false"
                                    accept="image/*"
                                    doNotResize="['gif', 'svg']"
                                    @input="savePhotoService">
                        <label for="image4" slot="upload-label">
                            <span class="btn btn-outline-secondary mt-1" style="width: initial;">
                                {{ translator.pujarimatge }}
                            </span>
                        </label>
                    </image-uploader>
                </div>

                <!-- Reporte de informe -->
                <h6 class="mt-4 mb-2">{{ translator.informe }}:</h6>
                <textarea v-model="serviceReport" class="form-control" rows="6"> </textarea>

                <!-- Precio -->
                <h6 class="mt-4 mb-2">{{ translator.import }}: </h6>
                <div class="input-group mb-3">
                    <input id="priceReport" type="text" v-model="servicePrice" class="col-3 form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">{{ serviceDetail.service_currency }}</span>
                    </div>
                </div>

                <button v-if="serviceReport == serviceDetail.service_report && servicePrice == serviceDetail.service_import && servicePhotos == 0" class="btn btn-outline-primary mt-1" disabled>
                    {{ translator.guardar }} {{ translator.informe | toLower }}
                </button>
                <button v-else @click="serveiGuardar" class="btn btn-primary mb-2">
                    <i class="fal fa-save pr-1"></i>
                    {{ translator.guardar }} {{ translator.informe | toLower }}
                </button>
            </div>

            <NavbarService :translator="translator" :user="user" />
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import NavbarService from "@/components/NavbarService.vue";

    export default {
        name: "ServiceDetail",
        components: {
            NavbarService,
        },
        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("service", ["serviceDetail"]),
        },
        watch: {
            translator() {
                this.$store.dispatch("service/getServiceDetail", { usr: this.$route.query.id, id: this.$route.params.service });
            },
            serviceDetail() {
                this.isPreLoad = false;
                this.servicePrice = this.ereaseZero(this.serviceDetail.service_import);
                this.serviceReport = this.serviceDetail.service_report;
                if (this.serviceDetail.service_image_4 == '') {
                    this.numPhoto = 4
                } else if (this.serviceDetail.service_image_5 == '') {
                    this.numPhoto = 5
                } else if (this.serviceDetail.service_image_6 == '') {
                    this.numPhoto = 6
                } else {
                    this.numPhoto = 0
                }
            }
        },
        data() {
            return {
                isPreLoad: false,
                isInitLoad: true,
                servicePhotos: [],
                numPhoto: 0,
                serviceReport: '',
                servicePrice: '',
                validService: true
            };
        },

        async created() {
            try {
                this.isPreLoad = true;
                this.translator
                    ? await this.$store.dispatch("service/getServiceDetail", { usr: this.$route.query.id, id: this.$route.params.service })
                    : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
            } catch (error) {
                console.error(error);
            }
        },

        methods: {
            savePhotoService(photo) {
                this.image = photo;
                console.log(photo);

                this.servicePhotos.push({
                    'img': this.numPhoto,
                    'foto': photo.dataUrl
                });
                this.serveiEnviarFotos()

            },

            serveiEnviarFotos() {
                try {
                    this.isPreLoad = true;
                    let img4 = "", img5 = "", img6 = "";

                    // Asignación botón con su número de foto
                    for (const value of this.servicePhotos) {
                        if (value.img === 4) {
                            img4 = value.foto;
                        } else if (value.img === 5) {
                            img5 = value.foto;
                        } else if (value.img === 6) {
                            img6 = value.foto;
                        }
                    }

                    // Envia info a la store para hacer el post
                    this.$store.dispatch("service/postServeiEnviarFotos", { usr: this.$route.query.id, id: this.$route.params.service, image4: img4, image5: img5, image6: img6 });

                    // Reset fotos
                    this.servicePhotos = [];
                    this.validService = true;
                } catch (error) {
                    console.error(error);
                } finally {
                    setTimeout(() => {
                        this.isPreLoad = false;
                    }, 7000);
                }
            },

            serveiGuardar() {
                try {
                    this.isPreLoad = true;
                    this.serviceReport != this.serviceDetail.service_report || this.servicePrice != this.serviceDetail.service_import
                        ? this.$store.dispatch("service/postServeiGuardarInforme", { usr: this.$route.query.id, id: this.$route.params.service, informe: this.serviceReport, preu: this.servicePrice })
                        : '';
                    this.$toast.success(this.translator.guardat);
                    this.validService = true;
                } catch (error) {
                    console.error(error)
                }
            },

            async endService() {
                if (this.serviceDetail.service_image_4 == '' && this.serviceDetail.service_image_5 == '' && this.serviceDetail.service_image_6 == '' && this.serviceDetail.service_report == '') {
                    this.validService = false;

                } else {
                    this.validService = true;
                    try {
                        this.isPreLoad = true;
                        await this.$store.dispatch("service/postEndService", { usr: this.$route.query.id, id: this.$route.params.service });
                        // Feedback al usuario
                        this.$toast.success(this.translator.guardat);
                        setTimeout(() => this.$router.push({ name: 'Service', query: { id: this.$route.query.id } }), 1000);
                    } catch (error) {
                        console.error(error)
                    } finally {
                        this.isPreLoad = false;
                    }
                }
            },

            ereaseZero(value) {
                if (!value) return "";
                value = String(value.includes(".00")) ? String(value).replace(".00", "") : ''
                return value;
            },

        },

        filters: {
            transformDate(value) {
                if (!value) return '';
                const backendDate = value.split('-');
                return `${backendDate[2]}/${backendDate[1]}/${backendDate[0]}`;
            },
            toLower(value) {
                if (!value) return "";
                value = value.toString();
                return value.toLowerCase();
            }
        },

    }
</script>

<style>  
    header .flex-titular {
        display: flex;
        justify-content: space-between;
        max-width: 752px;
        margin: auto;
    }

    header .link-atras {
        padding: 12px;
        color: #FFF;
    }

        header .link-atras:hover {
            color: #b5b5b5;
        }

    .fileinput {
        display: none;
    }

    .img-preview {
        max-width: 100%;
        height: auto;
    }

    input[type="file"]#camera0, input[type="file"]#camera1, input[type="file"]#camera2 {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .incidencia-titular {
        opacity: 0.6;
        font-size: 14px;
    }

    .incidencia-border-bottom {
        border-bottom: 1px solid #bfc1c256;
    }

    .btn-cerrar-incidencia {
        width: auto;
        position: absolute;
        right: 10px;
    }

    .input-group-text {
        border-right: 1px solid;
    }

    @media (max-width: 470px) {
        .btn-cerrar-incidencia {
            position: inherit;
            width: auto;
            margin: auto;
        }
    }

    .alert-dismissible .close {
        padding: 0 1.25rem;
    }
</style>